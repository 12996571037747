<template>
  <div class="user-info-cp">
    <p
      class="user-info-cp-btn"
      data-bs-toggle="collapse"
      data-bs-target="#userInfoCp"
      role="button"
      aria-expanded="false"
      aria-controls="userInfoCp"
    >
      <Image
        class="profile-picture"
        :url="profilePicture"
        v-if="profilePicture"
        :alt="userInfo.name"
      ></Image>
      <img
        class="profile-picture"
        v-else
        src="@/assets/other-images/nobody.png"
        alt="nobody"
      />
      <span class="ms-2">{{ userInfo.name }}</span>
      <i class="bi bi-caret-down-fill"></i>
    </p>
    <div class="collapse user-info-cp-lists" id="userInfoCp">
      <ul>
        <li
          class="pointer d-flex align-items-center justify-content-center"
          :class="{ 'user-info-active': currentPage === 'UserSideBar' }"
          @click="go('個人頁面')"
        >
          <img
            v-if="currentPage === 'UserSideBar'"
            src="@/assets/website-use/poke_R.png"
            alt=""
            style="width: 40px; height: 40px"
          />
          個人頁面
        </li>
        <li
          class="pointer d-flex align-items-center justify-content-center"
          :class="{ 'user-info-active': currentPage === 'SellerSideBar' }"
          @click="go('賣家')"
        >
          <img
            v-if="currentPage === 'SellerSideBar'"
            src="@/assets/website-use/poke_R.png"
            alt=""
            style="width: 40px; height: 40px"
          />
          賣家
        </li>
        <li
          class="pointer d-flex align-items-center justify-content-center"
          :class="{ 'user-info-active': currentPage === 'CustomerSideBar' }"
          @click="go('顧客')"
        >
          <img
            v-if="currentPage === 'CustomerSideBar'"
            src="@/assets/website-use/poke_R.png"
            alt=""
            style="width: 40px; height: 40px"
          />
          顧客
        </li>
        <li class="pointer" @click="go('登出')">登出</li>
      </ul>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from 'vuex'
// api
import { parseToken, sortoutParseTokenResult } from '../../methods/API/parseToken'

export default {
  data() {
    return {
      serverToken: '',
      userInfo: {},
      currentPage: '',
      profilePicture: '',
    }
  },
  created() {
    this.serverToken = this.$methods.getCookie('serverToken')
    this.checkCurrentPage()
    this.getUserInfo()
  },
  watch: {
    $route(to, from) {
      this.checkCurrentPage()
    },
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores
    }),
  },
  methods: {
    // 確認目前位置
    checkCurrentPage() {
      const currentPage = this.$route.matched[0].name
      this.currentPage = currentPage
    },
    // 前往
    go(where) {
      switch (where) {
        case '個人頁面':
          this.$router.push({name: 'S_LinkAccounts'})
          break;
        case '賣家':
          if (this.ownerStores.loading) return this.SweetAlert('other', '請稍後，正在為您準備賣場')
          const filterOwnerStores = this.ownerStores.data.filter(item => {
            return !item.store.deleted && item.is_inSubscription
          })
          if (filterOwnerStores.length === 0) return this.SweetAlert('other', '您目前尚未有任何賣場')
          let defaltSellerStoreId = null
          defaltSellerStoreId = filterOwnerStores[0].storeId
          if (!defaltSellerStoreId) return this.SweetAlert('other', '您目前尚未有任何賣場')
          this.$router.push(`/seller/store/${defaltSellerStoreId}/merchandise`)
          break;
        case '顧客':
          let href = ''
          let defaltCustomerStoreId = null
          if (this.customerStores.loading) return this.SweetAlert('other', '請稍後，正在為您準備賣場')
          if (this.customerStores.data.length === 0) href = '/customer/storeLists'
          else {
            this.customerStores.data.some(item => {
              if (item.verify) {
                defaltCustomerStoreId = item.storeId
                return true
              }
            })
            if (!defaltCustomerStoreId) href = '/customer/storeLists'
            else href = `/customer/store/${defaltCustomerStoreId}/shop`
          }
          window.open(href, "_blank");
          break;
        case '登出':
          const identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
          this.$methods.delCookie('serverToken')
          if (identity === 'seller') this.$router.push('/sellerLogin')
          else this.$router.push('/customerLogin')
          break;
      }
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      const vm = this
      const getPhysicalUserInfo = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getUserLineLink,getUserFacebookLink}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfo,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          const physicalUserInfo = res.data[0].objects[0]
          if (physicalUserInfo.userLineLink && physicalUserInfo.userLineLink.profilePicture) vm.profilePicture = physicalUserInfo.userLineLink.profilePicture
          else if (physicalUserInfo.userFacebookLink && physicalUserInfo.userFacebookLink.profilePicture) vm.profilePicture = physicalUserInfo.userFacebookLink.profilePicture
          vm.userInfo = physicalUserInfo
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        }
      })
    },
  },
}
</script>